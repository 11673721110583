:global {
  :local(.error-page-wrapper) {
    flex-direction: column;
    height: 100vh;
    background-color: #fff;

    img + p {
      margin: 3rem 0;
      font-family: 'Poppins', sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      text-align: center;
      letter-spacing: 0.04em;
    }

    .ant-btn {
      text-transform: none;
      color: #012138;
      font-weight: bold;
      line-height: 19px;
      &:hover {
        color: #012138;
      }
    }

    .ant-btn {
      padding: 1.5rem 1rem;
      display: flex;
      justify-content: center;
      align-items: center;
      svg {
        color: #cab041;
        background-color: #012138;
        border-radius: 50%;
        font-size: 18px;
        padding: 1.5px;
      }
    }
  }
}
